/*
    Site Wide Customizations
*/
:root {
    --bs-body-bg: #fff;
}

body {
    font-family: Arial, Helvetica, sans-serif;
}

/*
    Header Customizations
*/
.nav-container {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

#top-bar {
    height: 150px;
    width: 100%;
    background-color: $white !important;
    align-items: baseline;
}

#site-info {
    display: flex;
    width: 1300px;

    a img {
        height: 60px;
        margin-left: 5rem;
        max-width: 360px;
    }

    span {
        font-size: 2.5rem;
        margin-right: 5rem !important;

        a {
            color: $gnwtText;
            text-decoration: none;
        }
    }
}

#logo {
    flex-grow: 0;
}

#title {
    flex-grow: 1;
    text-align: right;
}

#navbar-row {
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $primary !important;

    #home-btn {
        color: $gnwtText;
    }
}

.nav-btn {
    color: $white !important;
    height: 100%;
    padding: 0 1rem;
    border-radius: 0;
}

#lang-btn {
    padding: 0 1rem;
    border-radius: 0;

    a {
        color: $white;
        height: 100%;
    }
}

#search-box {
    margin-right: 1rem;

    @media (max-width: 992px) {
        max-width: 350px;
    }
}

#navbar-row button, #navbar-row li i {
    background-color: $primary;
    border: 1px solid $primary;

    &:focus, &:hover {
        background-color: $gnwtDarkerBlue !important;
        border: 1px solid $gnwtDarkerBlue !important;
        box-shadow: none;
    }
}

.nav-item a i {
    color: $white;
}

#header-breadcrumbs {
    font-size: 1rem;
    line-height: 24px;
    background-color: $gnwtGray !important;
    color: $gnwtText !important;
    border-top: 5px solid $gnwtLightBlue;

    ul.breadcrumb {
        margin: 0;

        li {
            a {
                color: $gnwtText;
                text-decoration: none;
            }
        }

        .active {
            color: $black;
        }
    }
}

#header-breadcrumbs {
    font-size: 12px;
    line-height: 24px;
    background-color: $gnwtGray;
    color: $gnwtText;
    border-top: 5px solid $gnwtLightBlue;

    ul.breadcrumb {
        margin: 0;

        li {
            margin-right: 1rem;

            a {
                color: $gnwtText;
                text-decoration: none;
            }
        }

        > .active {
            color: $black;
        }

        li:last-child::after {
            display: none;
        }
    }
}

.dropdown-toggle::after {
    color: $white;
}

@media (max-width: 992px) {
    #navbar-row .nav-container {
        display: flex;
        flex-wrap: nowrap;
    }

    .navbar-nav {
        flex-direction: unset;
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 1rem;
    }
}

button.navbar-toggler {
    margin-left: 1rem;
}

/*
    Footer Customizations
*/
footer {
    background-color: $gnwtText;
    background-image: url(../images/footer.jpg);
    background-position: center 120px;
    background-repeat: no-repeat;
    min-height: 350px;

    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;

    h3 {
        text-align: center;
        font-size: 1rem;

        a,
        a:hover,
        a:focus,
        a:active {
            color: $white;
            font-weight: bold;
            text-decoration: none;
        }
    }

    .wordmark {
        margin-top: 20px;
    }

    .facebook {
        text-align: right;

        a {
            color: $gnwtText;
            background-color: $white;
            display: inline-block;
            border: 1px solid $white;
            padding: 2px 10px;
            border-radius: 4px;

            &:hover {
                background-color: $gnwtText;
                color: $white;
            }
        }
    }
}

@media (min-width: 979px) {
    footer {
        background-image: url(../images/footer.jpg);
        background-position: center 50px;

        height: 250px;

        margin-left: 0;
        margin-right: 0;
        min-height: auto;

        h3 {
            text-align: left;
        }

        .wordmark {
            margin-top: 50px;
            text-align: right;
        }
    }
}

/*
    Description Customizations
*/
#digital-object-gnwt-sidebar {
    margin: 20px 0;
}

/*
    Site-wide Customizations
*/
.text-primary {
    color: $gnwtText !important;
}